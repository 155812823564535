
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyUser, deleteLoftytoken} from './actions/auth.actions';
import { loftySendVisitProject } from './util/visitsprojects';

// pages
import paginaprincipaliigd5 from './pages/paginaprincipaliigd5/paginaprincipaliigd5'
import paginadebusquedadevehiculosh7n0y from './pages/paginadebusquedadevehiculosh7n0y/paginadebusquedadevehiculosh7n0y'
import formulariogzp3 from './pages/formulariogzp3/formulariogzp3'
import visualizacionvehiculox6cbn from './pages/visualizacionvehiculox6cbn/visualizacionvehiculox6cbn'


// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = ({loftytoken, addLoftyUserApp, deleteLoftytokenApp}) => {

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  useEffect(() => { loftySendVisitProject() }, []);

  

  return (
    <Router>
      <Switch>
			<Route exact path="/" component={paginaprincipaliigd5} />
			<Route exact path="/vehiculos" component={paginadebusquedadevehiculosh7n0y} />
			<Route exact path="/formulario" component={formulariogzp3} />
			<Route exact path="/visualizacion/:vehiculo" component={visualizacionvehiculox6cbn} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyUserApp: (user) => dispatch(addLoftyUser(user)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

