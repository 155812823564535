
import axios from '../config/axios';

export const url = '/api/vehiculosai6rp'


export const customvisualizarenelprincipalifwvk_6735058a9633993dfe9ed306 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/principal`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const custombusquedayxpfc_673506839633993dfe9ed535 = ({
  nombre54sc2i,
	saltoedwzn,

}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/busqueda`, {
      params: {
        nombre54sc2i,
				saltoedwzn,

      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const custombusquedabasicadevehiculos3iq75_673733bf9633993dfe9ee251 = ({
  nombregdtv6,

}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/busqueda/basica`, {
      params: {
        nombregdtv6,

      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const getOnevehiculosai6rp = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/673d0b0e9633993dfe9efd40/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      

