
import React, {useEffect, useState} from 'react';

import LoftyCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOnevehiculosai6rp } from '../../api/vehiculosai6rp.api'
import {customobtenerlistavehiculos65gqx_673ccd239633993dfe9ef982 } from '../../api/imagenesdevehculosly90y.api'

import './visualizacionvehiculox6cbn.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pvisualizacionvehiculox6cbn = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyendpoint_673d0b0e9633993dfe9efd40, setLoftyEndpoint_673d0b0e9633993dfe9efd40] = useState({});
		const [loftyres_673ccd239633993dfe9ef982_component_i2iogx, setloftyres_673ccd239633993dfe9ef982_component_i2iogx] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_673d0b0e9633993dfe9efd40 = await getOnevehiculosai6rp({ _id: match.params.vehiculo, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_673d0b0e9633993dfe9efd40(loftyres_673d0b0e9633993dfe9efd40.data);
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await customobtenerlistavehiculos65gqx_673ccd239633993dfe9ef982({
					vehiculou1lwp: match.params.vehiculo,
				});
				setloftyres_673ccd239633993dfe9ef982_component_i2iogx(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id673cb9941de4874021c0cb83" >

      <div id="iw4j" className="">
      
      <div id="ifxeud" className="">
      
      <div id="it2j54" className="">
      
      <div id="ips2" className="">
      
      <div
        id="idiuka"
        className=""
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim3j34g0c.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="icq3pj" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/vehiculos"
        type=""
      >
        <span>Busquede Vehículo</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/formulario"
        type=""
      >
        <span>Cotización</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="i29u1z"
        className=""
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
      
      <div id="itmdc" className="">
      
      <a
        target=""
        className="links "
        id="i2aqf"
        href="/"
        type="button"
      >
        
    <div id="i2g1w" className="">
    <span>Inicio</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="iwu1w"
        href="/vehiculos"
        type="button"
      >
        
    <div id="ib1qi" className="">
    <span>Búsqueda de Vehículos</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="i31ek"
        href="/formulario"
        type="button"
      >
        
    <div id="ipu5h" className="">
    <span>Cotización</span>
    </div>
    
      </a>
      
      </div>
      
      <div id="io767h" className="">
      
      <a
        target=""
        className="links "
        id="ibclo5"
        href="/"
        type="button"
      >
        
    <div id="i64dbt" className="">
    <span>Inicio</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="i28qdz"
        href="#"
        type="button"
      >
        
    <div id="i1xreu" className="">
    <span>Búsqueda de Vehículos</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="idayty"
        href="#"
        type="button"
      >
        
    <div id="i0vg2v" className="">
    <span>Cotización</span>
    </div>
    
      </a>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="in444s" className="">
      
      <div id="iuax99" className="">
      
        <div id="i2iogx" className="">
          
            <div>
              <LoftyCarousel
                responsive={{
                  superLargeDesktop: {
                    breakpoint: {max: 4000, min: 3000},
                    items: 1,
                  },
                  desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 1,
                  },
                  mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 1,
                  },
                }}
                infinite
                autoPlay
              >
                {
                  loftyres_673ccd239633993dfe9ef982_component_i2iogx.map((loftyitem_673ccd239633993dfe9ef982_component_i2iogx) => (
                    <div key={loftyitem_673ccd239633993dfe9ef982_component_i2iogx._id} className="" id="iy6xnm">
                      
        <img
          className=""
          id="iw8ccc"
          alt=""
          src={loftyitem_673ccd239633993dfe9ef982_component_i2iogx.loftyUrl}
        />
        
                    </div>
                  ))
                }
              </LoftyCarousel>
            </div>
            
        </div>
        
      <div id="i3xlkh" className="">
      
        <a
          target=""
          className=""
          id="izux1q"
          href="/formulario"
        >
          
    <div id="iglcoi" className="">
    <span>COTIZAR</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="iqkbwi" className="">
      
        <div id="in2wo5" className="">
        
        <div className="" id="iy4i8g">
          
          <div className="" id="ijyos">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.nombrevehiculo5e65h}
          </div>
          
        </div>
        
        <div className="" id="itk33q">
          
      <p className="" id="ijs6oy">
        <span>Potencia:</span>
      </p>
      
          <div className="" id="">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.potencialaop2}
          </div>
          
        </div>
        
        <div className="" id="ipgikm">
          
      <p className="" id="ixi6ik">
        <span>Transmisión:</span>
      </p>
      
          <div className="" id="">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.transmisinum3j8}
          </div>
          
        </div>
        
        <div className="" id="ivasjk">
          
      <p className="" id="i5kl9k">
        <span>Tracción:</span>
      </p>
      
          <div className="" id="igbvak">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.traccinfttus}
          </div>
          
        </div>
        
        <div className="" id="ievh71">
          
      <p className="" id="iwtjq9">
        <span>Consumo:</span>
      </p>
      
          <div className="" id="">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.consumowbqbs}
          </div>
          
        </div>
        
        <div className="" id="iqi7ow">
          
      <p className="" id="i97xxc">
        <span>Pantalla táctil:</span>
      </p>
      
          <div className="" id="">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.pantallatctilyflni}
          </div>
          
        </div>
        
        <div className="" id="i8jrwn">
          
      <p className="" id="i3spdk">
        <span>Airbags:</span>
      </p>
      
          <div className="" id="it4vgl">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.airbags9gs63}
          </div>
          
        </div>
        
        <div className="" id="i99a63">
          
      <p className="" id="ii0gya">
        <span>Color</span>
      </p>
      
          <div className="" id="">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.color1p99b}
          </div>
          
        </div>
        
        <div className="" id="iculnh">
          
      <p className="" id="iaqfuj">
        <span>Motor:</span>
      </p>
      
          <div className="" id="">
            {loftyendpoint_673d0b0e9633993dfe9efd40?.motor24lbsg}
          </div>
          
        </div>
        
        </div>
        
      </div>
      
      </div>
      
      <div id="icmje7" className="">
      
      <div id="ipjst4" className="">
      
      <div id="in4gsc" className="">
      
      <p className="" id="iy0bu7">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i6s1k4">
        <span>Búscanos en nuestras redes</span>
      </span>
      
      </p>
      
      <div id="ilijmz" className="">
      
        <a
          target=""
          className=""
          id="i68lj2"
          href="mailto:sac@carmaxhn.com"
        >
          
      <img
        className=""
        id="i137py"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q453x.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iqu7o1"
          href="https://www.instagram.com/carmaxhn/"
        >
          
      <img
        className=""
        id="iru1nq"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q475b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i6irgm"
          href="https://www.facebook.com/Carmaxhn"
        >
          
      <img
        className=""
        id="ix8eqg"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q465z.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i2gwnh"
          href="https://wa.me/message/NRUSGCZS27HCN1"
        >
          
      <img
        className=""
        id="izq2u3"
        src="https://assetsprojects.s3.amazonaws.com/404no3im3kg8irs.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="imqdmc" className="">
      
      <div id="is16l4" className="">
      
      <p className="" id="iqj6i5">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="itdh6f">
        <span>Búscanos en nuestras redes</span>
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="iklg6x"
          href="#"
        >
          
        <a
          target=""
          className=""
          id="i9g4xj"
          href="mailto:sac@carmaxhn.com"
        >
          
      <img
        className=""
        id="irya7h"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q453x.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i7qfbt"
          href="https://www.facebook.com/Carmaxhn"
        >
          
      <img
        className=""
        id="i7gf77"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q465z.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i1dymt"
          href="https://www.instagram.com/carmaxhn/"
        >
          
      <img
        className=""
        id="i9veu5"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q475b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i9zju9"
          href="https://wa.me/message/NRUSGCZS27HCN1"
        >
          
      <img
        className=""
        id="i54ol1"
        src="https://assetsprojects.s3.amazonaws.com/404no3im3kg8irs.png"
        alt="undefined"
      />
      
        </a>
        
        </a>
        
      </div>
      
      </div>
      
      <div id="ilgjxb" className="">
      
      <p className="" id="iuyhhs">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i987ss">
        <span>Grupo corporativo econo © 2024 | Todos los Derechos Reservados</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pvisualizacionvehiculox6cbn);
  