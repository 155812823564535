
import axios from '../config/axios';

export const url = '/api/imagenesdevehculosly90y'


export const customobtenerlistavehiculos65gqx_673ccd239633993dfe9ef982 = ({
  vehiculou1lwp,

}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/obtenerlistavehiculos`, {
      params: {
        vehiculou1lwp,

      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});



